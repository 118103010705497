import axios from "axios";

export const BASE_URL = "https://" + location.hostname;

export const restApiServices = axios.create({
  baseURL: BASE_URL,
  headers: {
    // eslint-disable-next-line prettier/prettier
    "Accept": "application/json",
    "Content-Type": "application/json"
  },
  useCredentails: true
});

export const getWebformFields = webform => {
  const url = `/webform_rest/${webform}/fields`;
  return restApiServices.get(url, {
    params: {
      _format: "json"
    }
  });
};
