<template>
  <span>
    <div id="main" class="container-fluid" :class="[featureDetectedClasses]">
      <div class="row">
        <Header />
      </div>
      <div id="container" class="row" autoscroll="true">
        <router-view />
      </div>
      <div class="row">
        <Footer />
      </div>
    </div>
  </span>
</template>

<script>
import detectIt from "detect-it";
import { mapState } from "vuex";
import { GET_METATAG } from "./store/getters-type";
import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";
let bodyTag = null;

export default {
  name: "App",
  components: {
    Header,
    Footer
  },
  data() {
    return {
      bodyClass: "page-welcome",
      featureDetectedClasses: []
    };
  },
  created() {
    detectIt.hasMouse ? (this.featureDetectedClasses = ["no-touch"]) : "";
  },
  mounted() {
    bodyTag = document.getElementsByTagName("body")[0];
    bodyTag.classList.add(this.bodyClass);
  },
  watch: {
    $route(to) {
      switch (to.name) {
        case "trips":
          this.addBodyClass("page-trips");
          break;
        case "trips-detail":
          this.addBodyClass("page-trips");
          break;
        case "tips":
          this.addBodyClass("page-tips");
          break;
        case "tips-detail":
          this.addBodyClass("page-tips");
          break;
        case "site-terms":
          this.addBodyClass("page-site-terms");
          break;
        case "page-not-found":
          this.addBodyClass("page-404");
          break;
        default:
          this.addBodyClass("page-welcome");
      }
    }
  },
  computed: mapState({
    metatag: state => state.metatag
  }),
  methods: {
    addBodyClass(className) {
      bodyTag.classList.remove(this.bodyClass);
      bodyTag.classList.add(className);
      this.bodyClass = className;
    }
  },
  metaInfo() {
    return {
      title: this.$route.meta.title,
      meta: this.$store.getters[`meta/${GET_METATAG}`]
    };
  }
};
</script>

<style lang="scss">
@import "./styles/site.scss";
</style>
