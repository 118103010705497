export const GET_HOMEPAGE_HERO_COMPONENT = "getHomepageHeroComponent";
export const GET_HOMEPAGE_VIDEO_COMPONENT = "getHomepageVideoComponent";
export const GET_HOMEPAGE_TRIP_COMPONENT = "getHomepageTripComponent";
export const GET_HOMEPAGE_TIPS_COMPONENT = "getHomepageTipsComponent";
export const GET_HOMEPAGE_TRIPS = "getHomepageTrips";
export const GET_FEATURED_TRIPS = "getFeaturedTrips";
// Tip landing page
export const GET_LANDING_TIPS = "getLandingTips";
export const GET_LANDING_BANNER = "getLandingBanner";
// Tip details page
export const GET_TIPS = "getTips";
export const GET_TIPS_POLL = "getTipsPoll";
export const GET_TIP_BY_SLUG = "getTipBySlug";
// Trips landing page
export const GET_TRIPS_HERO = "getTripsHero";
export const GET_TRIPS = "getTrips";
export const GET_TRIPS_POI = "getTripsPoi";
export const GET_FEATURED_TIPS_BY_TAG = "getFeaturedTipsByTag";
export const GET_FIRST_FEATURED_TIP_BY_TAG = "getFirstFeaturedTipByTag";
// Trip details page
export const GET_TRIP = "getTrip";
export const GET_TRIP_POI = "getTripPoi";
export const GET_TRIP_IMAGES = "getTripImages";
export const GET_TRIP_IMAGES_LARGE = "getTripImagesLarge";
export const GET_WEATHER_DATA = "getWeatherData";
// Metatag
export const GET_METATAG = "getMetatag";
export const GET_METADATA = "getMetadata";
export const GET_IS_LOADING = "getIsLoading";
