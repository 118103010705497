import Vue from "vue";
import Vuex from "vuex";
import meta from "./modules/meta";
import ui from "./modules/ui";
import tipLanding from "./modules/tip-landing";
import tipDetails from "./modules/tip-details";
import trip from "./modules/trip";

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    meta,
    ui,
    tipLanding,
    tipDetails,
    trip
  }
});
