<template>
  <div class="home layout-basic-page full-height">
    <Preloader :loading="isLoading" />
    <HomePage />
  </div>
</template>

<script>
import { mapState } from "vuex";
import Preloader from "@/components/Preloader.vue";
import HomePage from "@/components/HomePage.vue";

export default {
  name: "home",
  components: {
    HomePage,
    Preloader
  },
  computed: mapState({
    isLoading: state => state.ui.isLoading
  })
};
</script>
