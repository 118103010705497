<template>
  <span>
    <div class="traffic-graph" aria-hidden="true" v-if="this.trafficVisibile">
      <ul>
        <li class="first">Fast</li>
        <li class="green"></li>
        <li class="orange"></li>
        <li class="red"></li>
        <li class="maroon"></li>
        <li class="last">Slow</li>
      </ul>
    </div>
    <GoogleMapNavControl :map="map" :visible="this.navControl" />
    <div id="google-map" ref="googleMap"></div>
  </span>
</template>
<script>
/* eslint-disable no-undef */
import { mapState, mapMutations } from "vuex";
import {
  SET_MAP,
  SET_BOUNDS,
  SET_MARKERS,
  SET_MARKER_ICON,
  SET_CITY
} from "@/store/mutation-type";
import GoogleMapNavControl from "@/components/GoogleMapNavControl";
const australia = [-33.86, 151.2094];
export default {
  name: "GoogleMap",
  components: { GoogleMapNavControl },
  props: {
    navControl: {
      type: Boolean,
      default: true
    },
    tripList: {
      type: Boolean,
      default: false
    },
    isTripMarker: { type: Boolean, default: false },
    mapConfigDefault: {
      type: Object,
      default: () => {
        return {
          lat: -33.86,
          lng: 151.2094,
          center: { lat: australia[0], lng: australia[1] },
          zoom: 5,
          styles: [
            {
              featureType: "water",
              stylers: [
                {
                  color: "#313131"
                }
              ]
            },
            {
              featureType: "landscape",
              elementType: "geometry.fill",
              stylers: [
                {
                  color: "#444444"
                }
              ]
            },
            {
              featureType: "poi",
              elementType: "geometry",
              stylers: [
                {
                  color: "#444444"
                }
              ]
            },
            {
              elementType: "labels.text.stroke",
              stylers: [
                {
                  visibility: "off"
                }
              ]
            },
            {
              elementType: "labels.text.fill",
              stylers: [
                {
                  color: "#cccccc"
                }
              ]
            },
            {
              featureType: "road.highway",
              elementType: "geometry",
              stylers: [
                {
                  color: "#666666"
                }
              ]
            },
            {
              featureType: "road.arterial",
              elementType: "geometry",
              stylers: [
                {
                  color: "#555555"
                }
              ]
            },
            {
              elementType: "labels.icon",
              stylers: [
                {
                  hue: "#ffdd00"
                },
                {
                  saturation: -100
                },
                {
                  lightness: -30
                },
                {
                  gamma: 0.6
                }
              ]
            },
            {
              featureType: "road",
              elementType: "labels",
              stylers: [
                {
                  invert_lightness: true
                }
              ]
            },
            {
              featureType: "transit",
              stylers: [
                {
                  visibility: "off"
                }
              ]
            },
            {
              featureType: "road.local",
              stylers: [
                {
                  color: "#555555"
                }
              ]
            }
          ],
          panControl: false,
          zoomControl: false,
          mapTypeControl: false,
          scaleControl: false,
          streetViewControl: false,
          overviewMapControl: false,
          draggable: false,
          scrollwheel: false,
          disableDoubleClickZoom: true
        };
      }
    },
    mapConfig: {
      type: Object,
      default: () => {}
    },
    markerIcons: {
      type: Object,
      default: () => {
        return {
          large: {
            normal: {
              url: require("../assets/img/pin-large-grey@2x.png"),
              scaledSize: new google.maps.Size(86, 51),
              origin: new google.maps.Point(0, 0),
              anchor: new google.maps.Point(40, 40)
            },
            active: {
              url: require("../assets/img/pin-large-yellow@2x.png"),
              scaledSize: new google.maps.Size(86, 51),
              origin: new google.maps.Point(0, 0),
              anchor: new google.maps.Point(40, 40)
            }
          },
          small: {
            normal: {
              url: require("../assets/img/marker-small-grey@2x.png"),
              scaledSize: new google.maps.Size(43, 25)
            },
            active: {
              url: require("../assets/img/marker-small-yellow@2x.png"),
              scaledSize: new google.maps.Size(55, 32)
            }
          }
        };
      }
    },
    mapData: {
      type: Array,
      default: () => {
        return [];
      }
    }
  },
  mounted() {
    const mapContainer = this.$refs.googleMap;

    this.setMap(
      new google.maps.Map(mapContainer, {
        ...this.mapConfigDefault,
        ...this.mapConfig
      })
    );

    google.maps.event.addListenerOnce(this.map, "idle", function() {
      google.maps.event.trigger(this.map, "resize");
    });
    // trip listing on trip landing page
    if (this.tripList) {
      this.mapData.forEach(trip => {
        this.addMarker(trip);
      });

      this.fitMarkersToBounds();
    } else {
      // trip on trip details page
      this.map.trafficLayer = new google.maps.TrafficLayer();

      // if it's recreational trip
      if (this.mapData[0].field_trip_type === "recreational") {
        this.currentPoi = this.mapData[0].json_map_data.filter(poi => {
          return poi.type !== "trip";
        });
      } else {
        // It's commuter trip
        this.currentPoi = this.mapData[0].json_map_data.filter(poi => {
          return poi.type !== "city";
        });
        const city = this.mapData[0].json_map_data.filter(poi => {
          return poi.type === "city";
        });
        this.setCity(city);
      }
      // add markers based on above conditions
      this.currentPoi.forEach(trip => {
        this.addMarker(trip);
      });
      // fit all markers to fit to bounds
      this.fitMarkersToBounds();

      // Route the trip directions
      if (this.mapData[0].field_trip_type === "recreational") {
        // Add the route markers.
        this.addRoute();
      } else {
        // Zoom in a bit for Commuter trip
        google.maps.event.addListenerOnce(this.map, "bounds_changed", () => {
          this.map.setZoom(13);
        });
      }

      // Scenic routes are turned on by default, so keep them active, hide all other markers
      // If this is a commuter trip turn on parking & scenic markers by default
      this.markers.forEach(marker => {
        if (this.mapData[0].field_trip_type === "commuter") {
          // Commuter trip
          if (marker.poiType !== "parking" && marker.poiType !== "scenic") {
            marker.setMap(null);
          }
        } else {
          // Recrational trip, just turn on scenic markers
          if (marker.poiType !== "scenic") {
            marker.setMap(null);
          }
        }
      });
    }
  },
  data() {
    return {
      currentPoi: [],
      tripPoints: [],
      directionsRenderer: {},
      zoomLevel: 6
    };
  },
  computed: {
    ...mapState("trip", [
      "map",
      "bounds",
      "markers",
      "icon",
      "city",
      "trafficVisibile",
      "expanded"
    ])
  },
  watch: {
    expanded(newValue) {
      if (newValue) {
        this.setCenter();
        this.map.setOptions({
          draggable: true
        });
      } else {
        // condensed.
        this.setCenter();
        this.map.setOptions({
          draggable: false
        });
      }
    }
  },
  methods: {
    ...mapMutations("trip", {
      setMap: `${SET_MAP}`,
      setBounds: `${SET_BOUNDS}`,
      setMarkers: `${SET_MARKERS}`,
      setMarkerIcon: `${SET_MARKER_ICON}`,
      setCity: `${SET_CITY}`
    }),
    addRoute() {
      let origin,
        destination = null;
      const waypoints = [];
      const directionsService = new google.maps.DirectionsService();
      this.directionsRenderer = new google.maps.DirectionsRenderer({
        draggable: false,
        map: this.map,
        suppressMarkers: true,
        polylineOptions: {
          strokeColor: "#FFD300",
          strokeOpacity: 0.9,
          strokeWeight: 5
        }
      });

      if (this.mapData[0].field_trip_type === "recreational") {
        this.tripPoints = this.mapData[0].json_map_data.filter(poi => {
          return poi.type === "trip";
        });
        const numPaths = this.tripPoints.length - 1;
        this.tripPoints.forEach((mark, index) => {
          if (index === 0) {
            this.addMarker(mark);
            origin = new google.maps.LatLng(mark.lat, mark.lng);
          } else {
            // Destination
            if (index === numPaths) {
              destination = new google.maps.LatLng(mark.lat, mark.lng);
              this.addMarker(mark);
            } else {
              waypoints.push({
                location: new google.maps.LatLng(mark.lat, mark.lng),
                stopover: true
              });
            }
          }
          this.zoomLevel = this.map.getZoom();
        });

        const request = {
          origin: origin,
          destination: destination,
          optimizeWaypoints: true,
          travelMode: google.maps.TravelMode.DRIVING
        };
        if (waypoints.length > 0) {
          request.waypoints = waypoints;
        }
        directionsService.route(request, (result, status) => {
          if (status == "OK") {
            this.directionsRenderer.setDirections(result);
          }
        });
      }
    },
    setCenter() {
      let position = {};
      if (this.city.length) {
        position = new google.maps.LatLng(this.city[0].lat, this.city[0].lng);
      } else {
        position = new google.maps.LatLng(
          this.tripPoints[0].lat,
          this.tripPoints[0].lng
        );
      }
      this.map.setCenter(position);
      const display = this.directionsRenderer;
      google.maps.event.addListenerOnce(this.map, "idle", () => {
        google.maps.event.trigger(this.map, "resize");
        if (
          typeof display !== "undefined" &&
          typeof display.directions !== "undefined"
        ) {
          let bounds = new google.maps.LatLngBounds();
          for (let i = 0; i < display.directions.routes.length; i++) {
            bounds.extend(display.directions.routes[i].bounds.getNorthEast());
            bounds.extend(display.directions.routes[i].bounds.getSouthWest());
          }
          this.setBounds(bounds);
          this.map.fitBounds(bounds, 0);
          this.map.panToBounds(bounds);
          if (this.map.getZoom() < this.zoomLevel) {
            // try to get zoom working properly.
            this.map.setZoom(this.zoomLevel + 1);
          }
        }
      });
    },
    addMarker(item) {
      let marker;
      // Figure out which icons to use
      if (item.smallIcons === true) {
        this.setMarkerIcon(this.markerIcons.small);
      } else {
        this.setMarkerIcon(this.markerIcons.large);
      }

      if (this.tripList) {
        marker = new google.maps.Marker({
          position: new google.maps.LatLng(
            item.destination_geo.lat,
            item.destination_geo.lng
          ),
          id: item.id,
          title: item.title,
          slug: item.field_slug,
          icon: this.icon.normal,
          map: this.map
        });
      } else {
        // We are NOT adding marker that is for a trip on trip landing.
        let markerIcons = null;
        if (typeof item.type !== "undefined" && item.type !== "trip") {
          markerIcons = {
            url: require(`../assets/img/marker-${item.type}.png`)
          };
          // Different icons for POIS.
          if (item.type === "crash") {
            switch (item.title) {
              case "group-1":
                markerIcons.scaledSize = new google.maps.Size(16, 16);
                break;
              case "group-2":
                markerIcons.scaledSize = new google.maps.Size(21, 21);
                break;
              case "group-3":
                markerIcons.scaledSize = new google.maps.Size(24, 24);
                break;
              case "group-4":
                markerIcons.scaledSize = new google.maps.Size(28, 28);
                break;
              case "group-5":
                markerIcons.scaledSize = new google.maps.Size(34, 34);
                break;
              default:
                markerIcons.scaledSize = new google.maps.Size(34, 34);
                break;
            }
          } else {
            markerIcons = {
              url: require(`@/assets/img/marker-${item.type}.png`),
              scaledSize: new google.maps.Size(34, 34)
            };
          }
        } else {
          marker = new google.maps.Marker({
            position: new google.maps.LatLng(item.lat, item.lng),
            zIndex: 999,
            title: "", // no title for trip markers.
            icon: this.markerIcons.large.active,
            map: this.map,
            poiType: item.type
          });
        }
        // Check if trip is commuter or recreational. If commuter, center the map using a different object
        if (this.mapData[0].field_trip_type === "recreational") {
          marker = new google.maps.Marker({
            position: new google.maps.LatLng(item.lat, item.lng),
            zIndex: 9999,
            title: item.title,
            icon: markerIcons,
            map: this.map,
            poiType: item.type
          });
        } else {
          marker = new google.maps.Marker({
            position: new google.maps.LatLng(item.lat, item.lng),
            zIndex: 9999,
            title: item.title,
            icon: markerIcons,
            map: this.map,
            poiType: item.type
          });
        }
      }

      this.setMarkers(this.addInfoWindow(item, marker));
    },
    fitMarkersToBounds() {
      this.setBounds(new google.maps.LatLngBounds());
      if (typeof this.markers !== "undefined" && this.markers.length > 0) {
        this.markers.map(marker => {
          const position = marker.getPosition();
          if (!isNaN(position.lat()) && !isNaN(position.lng())) {
            this.bounds.extend(position);
          }
        });
      }
      this.map.fitBounds(this.bounds);
    },
    addInfoWindow(item, marker) {
      const self = this;
      let title = "";
      let description = "";
      let boxClass = "textInfobox";
      let infoWindow = null;

      // Trip list marker
      if (typeof item !== "undefined") {
        boxClass = boxClass + " is-trip-marker";
      }

      // Crash data info window
      if (item.type === "crash") {
        boxClass = boxClass + " isCrash";
        description = "Motorcycle crashes";

        switch (item.title) {
          case "group-1":
            title = "1-5";
            break;
          case "group-2":
            title = "6-10";
            break;
          case "group-3":
            title = "11-15";
            break;
          case "group-4":
            title = "16-20";
            break;
          case "group-5":
            title = "20+";
            break;
        }
      }
      // Every other text based info window
      else if (typeof item.title !== "undefined" && item.title !== "") {
        if (item.type === "parking") {
          title = item.pin_address;
        } else {
          title = item.title;
        }
      }

      // Subtitle
      if (
        typeof item.field_origin !== "undefined" &&
        item.field_origin !== "" &&
        typeof item.field_destination !== "undefined" &&
        item.field_destination !== ""
      ) {
        description += `<h3 class="sub-title">${item.field_origin}`;

        if (item.field_trip_type !== "commuter") {
          description += ` to ${item.field_destination}</h3>`;
        }
      }
      if (item.type === "parking") {
        description = `<h3 class="sub-title">${item.title} spaces available</h3>`;
      }

      // Distance
      if (
        typeof item.field_length !== "undefined" &&
        item.field_length !== "" &&
        item.field_trip_type !== "commuter"
      ) {
        description += `<p style="color: #999; margin: 0; font-size: 12px;">${Math.round(
          item.field_length
        )} KM</p>`;
      }

      // Details
      if (
        typeof item.details !== "undefined" &&
        item.details !== "" &&
        item.details !== "NULL"
      ) {
        description +=
          '<p style="color: #666; margin: 0; font-size: 14px;">' +
          item.details +
          "</p>";
      }

      let content = `<div id="infobox-window" style="padding: 5px;">
      <h2 style="color: #3c3c3c; text-transform: uppercase; margin: 0;" class="poi-title">${title}</h2>`;
      if (description !== "") {
        content += `<h3 class="sub-title">${description}</h3></div>`;
      }

      if (title !== "" || title != "NULL" || description !== "") {
        infoWindow = new InfoBox({
          content: content,
          disableAutoPan: true,
          minWidth: 82,
          boxClass: boxClass,
          boxStyle: {
            background: "#FFF",
            backgroundSize: "cover",
            width: "auto",
            height: "auto",
            padding: "10px",
            border: "1px solid #CCC"
          },
          isHidden: false,
          closeBoxURL: ""
        });
      }

      // Position the infoWindow
      if (infoWindow !== null) {
        marker.infoWindow = infoWindow;

        google.maps.event.addListener(infoWindow, "domready", () => {
          let x =
            document.getElementById("infobox-window").parentNode.offsetWidth /
            2;
          let y =
            document.getElementById("infobox-window").parentNode.offsetHeight *
              2 -
            20;

          // is a photo infoWindow
          if (typeof infoWindow.poi !== "undefined") {
            x = x - 10;
            y = y - 20;
          } else {
            // is text based infoWindow
            let boxClasses = infoWindow.boxClass_.split(" ");
            // check if !crash
            if (typeof boxClasses[1] === "undefined") {
              // is normal text infobox
              y = y + 40;
            }
          }

          document.getElementById(
            "infobox-window"
          ).parentNode.parentNode.style.left = `-${x}px`;
          document.getElementById(
            "infobox-window"
          ).parentNode.parentNode.style.top = `-${y}px`;
        });
      }

      // Redirect to the the specific trip page
      google.maps.event.addListener(marker, "click", function() {
        if (typeof marker.id !== "undefined") {
          self.$router.push({
            path: `${marker.slug}`
          });
        }
      });

      // Marker mouseover
      google.maps.event.addListener(marker, "mouseover", () => {
        if (typeof marker.infoWindow !== "undefined") {
          marker.infoWindow.open(this.map, marker);
        }

        if (this.tripList) {
          // if (!options.trip.active) {
          marker.setIcon(this.icon.active);
          // }
        }
      });

      // Marker mouseout
      google.maps.event.addListener(marker, "mouseout", () => {
        // if (
        //   (typeof item.info !== "undefined" && item.info.title !== "") ||
        //   typeof item.poi !== "undefined"
        // ) {
        if (typeof marker.infoWindow !== "undefined") {
          marker.infoWindow.close(this.map, marker);
        }

        if (this.tripList) {
          // if (!options.trip.active) {
          marker.setIcon(this.icon.normal);
          // }
        }
      });

      return marker;
    }
  }
};
</script>
