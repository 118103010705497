<template>
  <div class="tips box slideFadeInRight">
    <div class="inner">
      <h3>Tips</h3>
      <div class="text" v-if="getComponent">
        <h4>{{ getComponent.title }}</h4>
        <p v-if="getComponent.description">{{ getComponent.description }}</p>
        <a
          v-if="getComponent.link"
          :href="`${getComponent.link.uri}`"
          class="btn yellow"
          >{{ getComponent.link.title }}
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { GET_HOMEPAGE_TIPS_COMPONENT } from "@/store/getters-type";

export default {
  name: "TipsCTA",
  props: {},
  computed: {
    ...mapGetters({
      getComponent: `ui/${GET_HOMEPAGE_TIPS_COMPONENT}`
    })
  }
};
</script>
