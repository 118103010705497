<template>
  <header id="header" class="col-md-12">
    <div class="department-logo">
      <a href="http://www.transport.nsw.gov.au/" target="_blank" tabindex="1"
        >Transport for NSW</a
      >
    </div>
    <div class="site-logo">
      <router-link
        to="/"
        tag="a"
        tabindex="2"
        @click.prevent.native="closeMobileMenu()"
      >
        <img
          src="../assets/img/logo-default@2x.png"
          alt="Ride to Live"
          class="desktop"
        />
        <img
          src="../assets/img/logo-mobile.png"
          alt="Ride to Live"
          class="mobile"
        />
      </router-link>
    </div>
    <nav class="main-menu">
      <ul class="desktop">
        <li id="n-test">
          <a href="/hpt/index.html" target="_self" tabindex="3">Test</a>
        </li>
        <li id="n-trips">
          <router-link to="/trips" tag="a" tabindex="4" active-class="active"
            >Trips</router-link
          >
        </li>
        <li id="n-tips">
          <router-link
            :to="{ name: 'tips' }"
            tag="a"
            tabindex="5"
            active-class="active"
            >Tips</router-link
          >
        </li>
      </ul>
      <ul class="mobile">
        <li class="toggle">
          <a
            href=""
            @click.prevent.stop="toggleMobileMenu()"
            class="show-menu"
            :class="{ active: isMobileMenuOpened }"
            ><i></i> <span><span class="el-offscreen"></span> Menu</span></a
          >
        </li>
      </ul>
    </nav>
    <MobileMenu />
  </header>
</template>

<script>
import { mapState, mapMutations } from "vuex";
import { TOGGLE_MOBILE_MENU, CLOSE_MOBILE_MENU } from "@/store/mutation-type";
import MobileMenu from "@/components/MobileMenu.vue";

export default {
  name: "Header",
  components: { MobileMenu },
  computed: mapState({
    isMobileMenuOpened: state => state.ui.isMobileMenuOpened
  }),
  methods: {
    ...mapMutations({
      toggleMobileMenu: `ui/${TOGGLE_MOBILE_MENU}`,
      closeMobileMenu: `ui/${CLOSE_MOBILE_MENU}`
    })
  }
};
</script>
