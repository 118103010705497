/* eslint-disable no-dupe-keys */
/* eslint-disable prettier/prettier */
import jsonApi from "./jsonApiClient";
import { restApiServices, getWebformFields } from "./restApiClient";
const NODE_HOMEPAGE_UUID = "3913408f-9dc1-47da-b60a-904a1bc23051";
const TRIP_LANDING_PAGE_UUID = "867d2c19-6168-4b51-8453-6ba06487ab5f";
const TIP_LANDING_PAGE_UUID = "f368a1a9-f9d7-4418-9bae-50bc742b3100";

export const tripLandingServices = {
  get() {
    return jsonApi.get(`trip_landing_page/${TRIP_LANDING_PAGE_UUID}`, {
      include: "field_hero_image.field_media_image,field_trips.field_trip_type,field_trips.field_hero_image"
    });
  }
};

export const tripServices = {
  get(slug) {
    return jsonApi.get("trip", {
      fields: {
        vote_count: "vote_count"
      },
      include:
        "field_hero_image,field_trip_type,field_hazards,field_top_highlights,field_images.field_media_image,field_city_weather",
      filter: {
        condition: {
          path: "field_slug",
          value: slug
        }
      }
    }, { headers: {
      Pragma: "no-cache"
    }});
  },
  createTripSuggestion(resource, params, config) {
    // Sending requests using restful api service instead of jsonapi
    // because of Webform REST only supports restapi.
    return restApiServices.post(`${resource}`, params, config);
  },
  postVote(resource, params, config) {
    return restApiServices.post(`${resource}`, params, config);
  }
};

// Get homepage node from Drupal.
export const getHomepageService = {
  get() {
    const paramsIncludes = [
      "field_hero.field_media_asset",
      "field_video_tile.field_media_asset",
      "field_video_tile.field_media_asset.field_media_video_file",
      "field_trip_tile.field_media_asset",
      "field_cta_tile.field_media_asset"
    ];
    const params = {
      include: paramsIncludes.join(",")
    };

    return jsonApi.get(`homepage/${NODE_HOMEPAGE_UUID}`, params);
  },
  getFeaturedTrips() {
    const params = {
      "filter[status][value]": 1,
      "filter[field_featured_trip]": 1,
      "page[limit]": 3,
      include: "field_hero_image"
    };

    return jsonApi.get(`trip`, params);
  },
  processFeaturedTrips(homepageTrips) {
    const trips = [];

    homepageTrips.forEach(jsonTrip => {
      const trip = {};
      trip.title = jsonTrip.title;
      trip.slug = jsonTrip.field_slug;
      // Removes decimal point as values are coming as 53.0.
      trip.distance = parseFloat(jsonTrip.field_length).toFixed();
      // Get the hour and minutes.
      const totalMinutes = jsonTrip.field_duration;
      trip.hours = Math.floor(totalMinutes / 60);
      trip.minutes = totalMinutes % 60;

      if (jsonTrip.field_hero_image) {
        const image = jsonTrip.field_hero_image;
        if (image.field_media_image.meta.imageDerivatives) {
          trip.image =
            image.field_media_image.meta.imageDerivatives.links.trip_tile.href;
        }
      }
      trips.push(trip);
    });

    return trips;
  }
};

// Get data from the specified tip landing page content type.
export const tipLandingService = {
  get() {
    const params = {
      include:
        "field_tip_reference,field_tip_reference.field_hero_image,field_tip_reference.field_tip_type,field_top_banner,field_top_banner.field_media_asset"
    };
    return jsonApi.get(`tip_landing_page/${TIP_LANDING_PAGE_UUID}`, params);
  }
};

// Get a specific tip.
export const tipsService = {
  get(slug) {
    const params = {
      include:
        "field_hero_image,field_hero_image.field_media_image,field_tip_type",
      filter: {
        condition: {
          path: "field_slug",
          value: slug
        }
      }
    };
    return jsonApi.get("tip", params);
  },
  getTipsPoll() {
    return getWebformFields("tips_poll");
  },
  createTipsPoll(resource, params, config) {
    // Sending requests using restful api service instead of jsonapi
    // because of Webform REST only supports restapi.
    return restApiServices.post(`${resource}`, params, config);
  },
};

// Get paragraph by field name.
export const getCTAParagraphByFieldService = {
  /**
   * Get call-to-action paragraph type from parent field.
   *
   * @param homepage
   *   The homepage state.
   * @param name
   *   Parent field name. This would be the paragraph field which the paragraph is attached to.
   */
  get(homepage, name) {
    const paragraph = {};

    if (homepage[name]) {
      const getParagraph = homepage[name];
      paragraph.title = getParagraph.field_title;
      paragraph.description = getParagraph.field_description;
      paragraph.link = getParagraph.field_link_normalize;

      if (getParagraph["field_media_asset"]) {
        paragraph.media = this.getMedia(getParagraph["field_media_asset"]);
      }
    }

    return paragraph;
  },
  /**
   * Get media asset from feld.
   *
   * @param field
   *   The media asset field.
   */
  getMedia(field) {
    const media = {};

    if (field.type === "media--image") {
      if (field.field_media_image.meta.imageDerivatives) {
        media.url =
          field.field_media_image.meta.imageDerivatives.links.hero.href;
        media.type = field.type;
      }
    }

    if (field.type === "media--video") {
      if (field["field_media_video_file"]) {
        const getVideoFile = field["field_media_video_file"];
        if (getVideoFile.uri.url) {
          media.video_url =
            "https://" + location.hostname + getVideoFile.uri.url;
        }
      }

      if (field["field_video_image"]) {
        const getVideoThumbnail = field["field_video_image"];
        if (getVideoThumbnail.meta.imageDerivatives) {
          media.url = getVideoThumbnail.meta.imageDerivatives.links.hero.href;
          media.type = field.type;
        }
      }
    }
    return media;
  }
};
