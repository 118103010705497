<template>
  <transition name="fade">
    <div id="global-loader" class="global-loader" v-if="loading">
      <div class="background">
        <div class="spinner">
          <div class="background"></div>
          <div class="percentage"></div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  name: "Preloader",
  props: {
    loading: {
      type: Boolean,
      default: false
    }
  }
};
</script>
