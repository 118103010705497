<template>
  <div class="featured-trips box">
    <div class="inner" v-if="getTrips">
      <h3 class="el-offscreen">Carousel</h3>
      <Carousel
        :indicators="indicators"
        :controls="controls"
        :interval="interval"
        ref="carousel"
        class="featured-trips-carousel"
      >
        <a v-on:click.prevent="togglePlay" class="toggle" :class="playClass"
          >{{ playClass }} Carousel</a
        >
        <Slide v-for="(slide, index) in getTrips" :key="index">
          <div
            style="width: 100%; height: 100%"
            :style="{ backgroundImage: `url('${slide.image}')` }"
            class="item--image"
            v-on:click="redirect(slide.slug)"
          ></div>
          <div class="overlay">
            <h3>Featured</h3>
            <p class="location">{{ slide.title }}</p>
            <ul>
              <li>{{ slide.distance }}<span class="label">KM</span></li>
              <li>
                <span class="hours" v-if="slide.hours > 0">
                  {{ slide.hours }}<span class="label">HRS</span>
                </span>
                <span class="minutes">
                  {{ slide.minutes }}<span class="label">MINS</span>
                </span>
              </li>
            </ul>
          </div>
        </Slide>
      </Carousel>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { GET_HOMEPAGE_TRIPS } from "@/store/getters-type";
import { Carousel, Slide } from "uiv";

export default {
  name: "FeatureTripsCTA",
  components: {
    Carousel,
    Slide
  },
  props: {},
  computed: {
    ...mapGetters({
      getTrips: `ui/${GET_HOMEPAGE_TRIPS}`
    }),
    playClass() {
      return this.interval > 0 ? "pause" : "play";
    }
  },
  data() {
    return {
      interval: 4000,
      indicators: true,
      controls: false
    };
  },
  methods: {
    togglePlay() {
      if (this.interval > 0) {
        this.interval = 0;
      } else {
        this.interval = 4000;
      }
    },
    redirect: function(path) {
      this.$router.push(path);
    }
  }
};
</script>
