<template>
  <div id="video-wrapper" :style="backgroundImage">
    <div id="video-container" aria-hidden="true">
      <div class="patch-overlay"></div>
    </div>
    <div class="text" v-if="getComponent">
      <h2>{{ getComponent.title }}</h2>
      <p v-if="getComponent.description">{{ getComponent.description }}</p>
      <a
        v-if="getComponent.link"
        :href="`${getComponent.link.uri}`"
        target="_self"
        class="btn white large"
        >{{ getComponent.link.title }}
      </a>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { GET_HOMEPAGE_HERO_COMPONENT } from "@/store/getters-type";

export default {
  name: "HeroCTA",
  props: {},
  computed: {
    ...mapGetters({
      getComponent: `ui/${GET_HOMEPAGE_HERO_COMPONENT}`
    }),
    // eslint-disable-next-line vue/return-in-computed-property
    backgroundImage() {
      if (this.getComponent) {
        if (this.getComponent.media) {
          const imageUrl = this.getComponent.media.url;
          return {
            background: `#383838 url(${imageUrl}) center center no-repeat`,
            "background-size": "cover"
          };
        }
      }
    }
  }
};
</script>
