import Vue from "vue";
import Router from "vue-router";
import VueGtm from "vue-gtm";
import Home from "./views/Home.vue";
Vue.use(Router);

const router = new Router({
  mode: "history",
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
  routes: [
    {
      path: "/",
      name: "home",
      component: Home
    },
    {
      path: "/trips",
      name: "trips",
      meta: {
        title: "Trips - Ride to Live"
      },
      // route level code-splitting
      // this generates a separate chunk (trips.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () =>
        import(/* webpackChunkName: "trips" */ "./views/Trips.vue")
    },
    {
      path: "/trips/:slug",
      name: "trips-detail",
      component: () =>
        import(
          /* webpackChunkName: "trips-details" */ "./views/TripsDetail.vue"
        )
    },
    {
      path: "/tips",
      name: "tips",
      // route level code-splitting
      // this generates a separate chunk (trips.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import(/* webpackChunkName: "tips" */ "./views/Tips.vue")
    },
    {
      path: "/tips/:slug",
      name: "tips-detail",
      component: () =>
        import(/* webpackChunkName: "tips" */ "./views/TipDetail.vue")
    },
    {
      path: "/pages/site-terms",
      name: "site-terms",
      component: () =>
        import(/* webpackChunkName: "site-terms" */ "./views/SiteTerms.vue")
    },
    {
      path: "/hpt/com-buffering-intersection",
      redirect: () => {
        window.location.href = "/hpt/index.html?type=commuter&id=0";
      }
    },
    {
      path: "/hpt/com-cornering-right",
      redirect: () => {
        window.location.href = "/hpt/index.html?type=commuter&id=1";
      }
    },
    {
      path: "/hpt/com-following-distance",
      redirect: () => {
        window.location.href = "/hpt/index.html?type=commuter&id=2";
      }
    },
    {
      path: "/hpt/com-buffering",
      redirect: () => {
        window.location.href = "/hpt/index.html?type=commuter&id=3";
      }
    },
    {
      path: "/hpt/com-cornering-left-right",
      redirect: () => {
        window.location.href = "/hpt/index.html?type=commuter&id=4";
      }
    },
    {
      path: "/hpt/com-intersection",
      redirect: () => {
        window.location.href = "/hpt/index.html?type=commuter&id=5";
      }
    },
    {
      path: "/hpt/rec-right-corner",
      redirect: () => {
        window.location.href = "/hpt/index.html?type=recreational&id=0";
      }
    },
    {
      path: "/hpt/rec-buffering",
      redirect: () => {
        window.location.href = "/hpt/index.html?type=recreational&id=1";
      }
    },
    {
      path: "/hpt/rec-intersection",
      redirect: () => {
        window.location.href = "/hpt/index.html?type=recreational&id=2";
      }
    },
    {
      path: "/hpt/rec-left-right-corner",
      redirect: () => {
        window.location.href = "/hpt/index.html?type=recreational&id=3";
      }
    },
    {
      path: "/hpt/rec-following-distance",
      redirect: () => {
        window.location.href = "/hpt/index.html?type=recreational&id=4";
      }
    },
    {
      path: "*",
      name: "page-not-found",
      component: () =>
        import(
          /* webpackChunkName: "page-not-found" */ "./views/PageNotFound.vue"
        )
    }
  ]
});

Vue.use(VueGtm, {
  id: ["GTM-PFJ85D5", "GTM-KLLBD2S"], // Your GTM single container ID or array of container ids ['GTM-xxxxxxx', 'GTM-yyyyyyy']
  queryParams: {
    // Add url query string when load gtm.js with GTM ID (optional)
  },
  enabled: true, // defaults to true. Plugin can be disabled by setting this to false for Ex: enabled: !!GDPR_Cookie (optional)
  debug: false, // Whether or not display console logs debugs (optional)
  loadScript: true, // Whether or not to load the GTM Script (Helpful if you are including GTM manually, but need the dataLayer functionality in your components) (optional)
  vueRouter: router, // Pass the router instance to automatically sync with router (optional)
  ignoredViews: [] // If router, you can exclude some routes name (case insensitive) (optional)
});

export default router;
