import "./styles/vendor/bootstrap.min.css";
import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import meta from "./meta";
import { LOADING_START, LOADING_END } from "./store/mutation-type";
import { apiServices } from "./api/jsonApiClient";

Vue.config.productionTip = false;

apiServices.interceptors.request.use(
  config => {
    store.commit(`ui/${LOADING_START}`);
    return config;
  },
  error => {
    return Promise.reject(error);
  }
);

apiServices.interceptors.response.use(
  config => {
    store.commit(`ui/${LOADING_END}`);
    return config;
  },
  error => {
    return Promise.reject(error);
  }
);

new Vue({
  router,
  store,
  meta,
  render: h => h(App)
}).$mount("#app");
