import Vue from "vue";
import { tipLandingService } from "@/api/services";
import { FETCH_TIP_LANDING } from "../actions-type";
import { metatagService } from "@/api/meta-services";
import * as R from "ramda";
import {
  SET_TIPS_LANDING_LOADED,
  SET_LANDING_TIPS_LOADING_STATE,
  SET_SELECTED_FILTER
} from "../mutation-type";
import {
  GET_LANDING_TIPS,
  GET_LANDING_BANNER,
  GET_FEATURED_TIPS_BY_TAG,
  GET_FIRST_FEATURED_TIP_BY_TAG,
  GET_METADATA,
  GET_IS_LOADING
} from "../getters-type";

const state = {
  landingTips: {},
  landingTipsBanner: {},
  metadata: {},
  isLoading: false,
  selected: ""
};

const getters = {
  [GET_LANDING_TIPS]: state => {
    const resultArray = Object.keys(state.landingTips).map(
      key => state.landingTips[key]
    );
    return resultArray;
  },
  [GET_LANDING_BANNER]: state => state.landingTipsBanner,
  [GET_METADATA]: state => {
    return state.metadata ? state.metadata : [{ content: "", name: "" }];
  },
  [GET_IS_LOADING]: state => {
    return state.isLoading;
  },
  [GET_FIRST_FEATURED_TIP_BY_TAG]: state => {
    const tag = state.selected.replace(/_/g, " ");
    const emptyTip = {
      id: "",
      image: "",
      title: "",
      hasVideo: true,
      isFeatured: false,
      description: "",
      path: "",
      type: "",
      tags: []
    };

    let items;

    const thetips = Object.keys(state.landingTips).map(
      key => state.landingTips[key]
    );
    const hasTag = n => {
      return R.contains(tag, n.tags);
    };
    if (tag !== "show all") {
      items = R.filter(hasTag, thetips);
    } else {
      items = R.clone(thetips);
    }
    const featured = items[0];
    return featured ? featured : emptyTip;
  },
  [GET_FEATURED_TIPS_BY_TAG]: state => (tag, currentTip) => {
    const emptyTip = {
      id: "",
      image: "",
      title: "",
      hasVideo: true,
      isFeatured: false,
      description: "",
      path: "",
      type: "",
      tags: []
    };
    const hasTag = n => {
      return R.contains(tag, n.tags);
    };
    const notCurrent = n => {
      return R.contains(currentTip.id, n.id);
    };
    // Don't return the current tip.
    const items = R.reject(notCurrent, R.filter(hasTag, state.landingTips));
    const keys = R.keys(items);
    const rando = keys[Math.floor(Math.random() * keys.length)];
    return state.landingTips ? items[rando] : emptyTip;
  }
};

const actions = {
  async [FETCH_TIP_LANDING]({ commit }) {
    // @TODO check if we want to refresh on each visit to /tips
    if (R.isEmpty(state.landingTips)) {
      commit(SET_LANDING_TIPS_LOADING_STATE, { isLoading: true });
      const data = await tipLandingService.get();
      commit(SET_TIPS_LANDING_LOADED, data);
      commit(SET_LANDING_TIPS_LOADING_STATE, { isLoading: false });
    }
  }
};
const mutations = {
  [SET_SELECTED_FILTER](state, payload) {
    state.selected = payload;
  },
  [SET_LANDING_TIPS_LOADING_STATE](state, { isLoading }) {
    state.isLoading = isLoading;
  },
  [SET_TIPS_LANDING_LOADED](state, landingTips) {
    state.metadata = metatagService.update(landingTips.metatag);
    const bannerImage = () => {
      return R.path(
        [
          "field_top_banner",
          "field_media_asset",
          "field_media_image",
          "meta",
          "imageDerivatives",
          "links",
          "hero",
          "href"
        ],
        landingTips
      );
    };
    const { field_top_banner } = landingTips;

    if (field_top_banner) {
      createbanner(
        state,
        field_top_banner.id,
        field_top_banner.field_title,
        bannerImage(),
        field_top_banner.field_description
      );
    }

    landingTips.field_tip_reference.forEach(tip => {
      const img = R.path(
        [
          "field_hero_image",
          "field_media_image",
          "meta",
          "imageDerivatives",
          "links",
          "tip_tile",
          "href"
        ],
        tip
      );
      if (!state.landingTips[tip.id]) {
        createTip(
          state,
          tip.id,
          tip.field_tile_title,
          tip.field_description,
          tip.field_has_video,
          tip.field_featured_tip,
          tip.field_slug,
          img,
          R.pluck("name", tip.field_tip_type)
        );
      }
    });
  }
};

const createbanner = (state, id, title, image, description) => {
  Vue.set(state.landingTipsBanner, "banner", {
    id,
    title,
    image,
    description
  });
};

const createTip = (
  state,
  id,
  title,
  description,
  hasVideo,
  isFeatured,
  path,
  image,
  tags
) => {
  Vue.set(state.landingTips, id, {
    id,
    title,
    description,
    hasVideo,
    isFeatured,
    path,
    image,
    tags
  });
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
