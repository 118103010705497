<template>
  <nav id="m-menu" class="m-menu">
    <ul class="links" :class="{ active: isMobileMenuOpened }">
      <li>
        <a
          class="test"
          href="/hpt/index.html"
          target="_self"
          tabindex="2"
          active-class="active"
          @click="toggleMobileMenu()"
          >Test</a
        >
      </li>
      <li>
        <router-link
          to="/trips"
          tag="a"
          tabindex="3"
          class="trips"
          active-class="active"
          @click="toggleMobileMenu()"
          >Trips</router-link
        >
      </li>
      <li>
        <router-link
          to="/tips"
          tag="a"
          tabindex="4"
          class="tips"
          active-class="active"
          @click="toggleMobileMenu()"
          >Tips</router-link
        >
      </li>
    </ul>
  </nav>
</template>

<script>
import { mapState, mapMutations } from "vuex";
import { TOGGLE_MOBILE_MENU } from "@/store/mutation-type";

export default {
  name: "MobileMenu",
  computed: mapState({
    isMobileMenuOpened: state => state.ui.isMobileMenuOpened
  }),
  methods: {
    ...mapMutations({
      toggleMobileMenu: `ui/${TOGGLE_MOBILE_MENU}`
    })
  }
};
</script>
<style scoped lang="scss">
.page-welcome {
  .links {
    top: 4px;
    @media all and (min-width: $tablet) {
      top: 23px;
    }
  }
}
.m-menu {
  position: relative;
}
.links {
  height: 0;
  list-style: none;
  position: absolute;
  top: 4px;
  transition: height 0.5s;
  z-index: 5;
  background-color: rgba(0, 0, 0, 0.5);
  text-align: center;
  width: 100%;
  padding: 0;

  &.active {
    height: 70px;
    transition: height 0.5s;

    li {
      height: 70px;

      a {
        height: 70px;
        transition: height 0.2s;

        &.test {
          height: 49px;
        }
        &.trips {
          height: 46px;
        }
        &.tips {
          height: 49px;
        }
      }
    }
  }

  @media all and (min-width: $tablet) {
    top: 8px;
  }

  @media all and (min-width: $desktop) {
    display: none;
  }

  li {
    height: 0;
    width: 96px;
    background: none;
    display: inline-block;
    float: none;

    &.first {
      background-position: 91px 29px;
    }

    &.last {
      background-position: -101px 29px;
    }

    a {
      display: block;
      text-indent: -9999px;
      height: 0;
      transition: height 0.2s;

      &.test {
        background: asset("img/spritesheet_header.png") no-repeat;
        height: 0;
        margin: 9px auto 12px;
        width: 43px;

        &:hover,
        &.active {
          background-position: 0 -49px;
        }
      }

      &.trips {
        background: asset("img/spritesheet_header.png") no-repeat -44px 0;
        height: 0;
        margin: 12px auto 12px;
        width: 40px;

        &:hover,
        &.active {
          background-position: -44px -49px;
        }
      }

      &.tips {
        background: asset("img/spritesheet_header.png") no-repeat -85px 0;
        height: 0;
        margin: 10px auto 11px;
        width: 49px;

        &:hover,
        &.active {
          background-position: -85px -49px;
        }
      }
    }
  }
}
</style>
