export const FETCH_TIP_LANDING = "fetchTipLanding";
export const FETCH_TIP_BY_ID = "fetchTipById";
export const FETCH_TIP_DETAILS = "fetchTipDetails";
export const ENSURE_LANDING_TIPS_LOADED = "ensureLandingTipsLoaded";
export const FETCH_HOMEPAGE = "fetchHomepage";
export const FETCH_TRIPS = "fetchTrips";
export const FETCH_TRIP = "fetchTrip";
export const FETCH_HOMEPAGE_TRIPS = "fetchHomepageTrips";
export const SUGGEST_TRIP_CREATE = "suggestTripCreate";
export const UP_VOTE_REQUEST = "upVoteRequest";
export const DOWN_VOTE_REQUEST = "downVoteRequest";
