import Vue from "vue";
import { tipsService } from "@/api/services";
import { FETCH_TIP_BY_ID } from "../actions-type";
import { metatagService } from "@/api/meta-services";
import * as R from "ramda";
import {
  SET_TIP_LOADED,
  SET_TIPS_LOADING_STATE,
  SET_TIPS_POLL
} from "../mutation-type";
import {
  GET_TIPS,
  GET_TIPS_POLL,
  GET_TIP_BY_SLUG,
  GET_METADATA,
  GET_IS_LOADING
} from "../getters-type";
const state = {
  tips: {},
  isLoading: false,
  tipsPoll: null
};

const getters = {
  [GET_TIPS]: state => {
    return state.tips;
  },
  [GET_TIPS_POLL]: state => {
    return state.tipsPoll;
  },
  [GET_TIP_BY_SLUG]: state => slug => {
    return state.tips ? state.tips[slug] : {};
  },
  [GET_IS_LOADING]: state => {
    return state.isLoading;
  },
  [GET_METADATA]: state => slug => {
    return state.tips[slug]
      ? state.tips[slug].metadata
      : [{ content: "", name: "" }];
  }
};
const actions = {
  async [FETCH_TIP_BY_ID]({ commit }, slug) {
    commit(SET_TIPS_LOADING_STATE, { isLoading: true });
    const data = await tipsService.get(slug);
    commit(SET_TIP_LOADED, data);
    // Check if tip is a poll.
    const tip = state.tips[slug];
    if (tip.isPoll) {
      // Get poll fields.
      const poll = await tipsService.getTipsPoll();
      commit(SET_TIPS_POLL, poll);
    }
    commit(SET_TIPS_LOADING_STATE, { isLoading: false });
  }
};

const mutations = {
  [SET_TIPS_LOADING_STATE](state, { isLoading }) {
    state.isLoading = isLoading;
  },

  [SET_TIPS_POLL](state, { data }) {
    state.tipsPoll = data;
  },

  [SET_TIP_LOADED](state, tip) {
    const theTip = tip[0];
    if (!state.tips[tip[0].id]) {
      const img = R.path(
        ["field_hero_image", "field_media_image", "links", "tip_hero", "href"],
        theTip
      );
      createTip(
        state,
        theTip.id,
        theTip.field_tile_title,
        theTip.field_description,
        theTip.field_body,
        theTip.field_has_video,
        theTip.field_featured_tip,
        theTip.field_is_poll,
        theTip.field_slug,
        metatagService.update(theTip.metatag),
        img,
        R.pluck("name", theTip.field_tip_type)
      );
    }
  }
};

function createTip(
  state,
  id,
  title,
  description,
  body,
  hasVideo,
  isFeatured,
  isPoll,
  path,
  metadata,
  image,
  type
) {
  Vue.set(state.tips, path, {
    id,
    title,
    description,
    body,
    hasVideo,
    isFeatured,
    isPoll,
    path,
    metadata,
    image,
    type
  });
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
