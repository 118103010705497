import { SET_METATAG } from "../mutation-type";
import { GET_METATAG } from "../getters-type";

const state = {
  metatag: {}
};

const getters = {
  [GET_METATAG](state) {
    return Array.isArray(state.metatag) ? state.metatag : "";
  }
};

const mutations = {
  [SET_METATAG](state, metatag) {
    state.metatag = metatag;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  mutations
};
