import {
  getHomepageService,
  getCTAParagraphByFieldService
} from "@/api/services";
import { metatagService } from "@/api/meta-services";
import { FETCH_HOMEPAGE, FETCH_HOMEPAGE_TRIPS } from "../actions-type";
import {
  TOGGLE_MOBILE_MENU,
  CLOSE_MOBILE_MENU,
  LOADING_START,
  LOADING_END,
  SET_HOMEPAGE,
  SET_HOMEPAGE_TRIPS,
  SET_METATAG
} from "../mutation-type";
import {
  GET_HOMEPAGE_HERO_COMPONENT,
  GET_HOMEPAGE_VIDEO_COMPONENT,
  GET_HOMEPAGE_TRIP_COMPONENT,
  GET_HOMEPAGE_TIPS_COMPONENT,
  GET_HOMEPAGE_TRIPS,
  GET_FEATURED_TRIPS
} from "../getters-type";

const state = {
  isMobileMenuOpened: false,
  isLoading: false,
  homepage: {},
  homepageTrips: [],
  mapConfig: {
    draggable: true,
    zoom: 7,
    disableDoubleClickZoom: false
  }
};

const getters = {
  [GET_HOMEPAGE_HERO_COMPONENT](state) {
    if (state.homepage) {
      return getCTAParagraphByFieldService.get(state.homepage, "field_hero");
    }
  },
  [GET_HOMEPAGE_VIDEO_COMPONENT](state) {
    if (state.homepage) {
      return getCTAParagraphByFieldService.get(
        state.homepage,
        "field_video_tile"
      );
    }
  },
  [GET_HOMEPAGE_TRIP_COMPONENT](state) {
    if (state.homepage) {
      return getCTAParagraphByFieldService.get(
        state.homepage,
        "field_trip_tile"
      );
    }
  },
  [GET_HOMEPAGE_TIPS_COMPONENT](state) {
    if (state.homepage) {
      return getCTAParagraphByFieldService.get(
        state.homepage,
        "field_cta_tile"
      );
    }
  },
  [GET_HOMEPAGE_TRIPS](state) {
    if (state.homepageTrips.length > 0) {
      return getHomepageService.processFeaturedTrips(state.homepageTrips);
    }
  },
  [GET_FEATURED_TRIPS]: state => {
    return state.homepageTrips.map(item => {
      const featuredTrips = item.json_map_data.filter(poi => {
        return poi.type === "trip";
      });
      return {
        id: item.id,
        title: item.title,
        field_slug: item.field_slug,
        destination_geo: featuredTrips.pop(),
        smallIcons: true
      };
    });
  }
};

const actions = {
  async [FETCH_HOMEPAGE]({ commit }) {
    const data = await getHomepageService.get();
    commit(SET_HOMEPAGE, data);
    const { metatag } = data;
    if (metatag) {
      commit(`meta/${SET_METATAG}`, metatagService.update(metatag), {
        root: true
      });
    }
  },
  async [FETCH_HOMEPAGE_TRIPS]({ commit }) {
    const data = await getHomepageService.getFeaturedTrips();
    commit(SET_HOMEPAGE_TRIPS, data);
  }
};

const mutations = {
  [TOGGLE_MOBILE_MENU](state) {
    state.isMobileMenuOpened = !state.isMobileMenuOpened;
  },
  [CLOSE_MOBILE_MENU](state) {
    state.isMobileMenuOpened = false;
  },
  [LOADING_START](state) {
    state.isLoading = true;
  },
  [LOADING_END](state) {
    state.isLoading = false;
  },
  [SET_HOMEPAGE](state, payload) {
    state.homepage = payload;
  },
  [SET_HOMEPAGE_TRIPS](state, payload) {
    state.homepageTrips = payload;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
