<template>
  <div class="controls" v-if="visible">
    <a href class="zoom-in" @click.prevent="zoomIn()">
      +
      <span class="el-offscreen">Zoom In</span>
    </a>
    <a href class="zoom-out" @click.prevent="zoomOut()">
      -
      <span class="el-offscreen">Zoom Out</span>
    </a>
  </div>
</template>

<script>
export default {
  name: "GoogleMapNavControl",
  props: {
    visible: {
      type: Boolean,
      default: true
    },
    map: {
      type: Object,
      default() {
        return {};
      }
    }
  },
  methods: {
    zoomIn() {
      this.map.setZoom(this.map.getZoom() + 1);
    },
    zoomOut() {
      this.map.setZoom(this.map.getZoom() - 1);
    }
  }
};
</script>
