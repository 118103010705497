<template>
  <div id="video-wrapper-bottom">
    <div id="video-container" aria-hidden="true" v-if="backgroundVideo">
      <VideoBg
        :sources="[backgroundVideo.video_url]"
        :img="backgroundVideo.image_url"
      />
    </div>
    <div class="panel">
      <div class="inner" v-if="getComponent">
        <h2>{{ getComponent.title }}</h2>
        <p v-if="getComponent.description">{{ getComponent.description }}</p>
        <a
          v-if="getComponent.link"
          :href="`${getComponent.link.uri}`"
          target="_self"
          class="btn white large"
          >{{ getComponent.link.title }}
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import VideoBg from "vue-videobg";
import { GET_HOMEPAGE_VIDEO_COMPONENT } from "@/store/getters-type";

export default {
  name: "VideoCTA",
  components: {
    VideoBg
  },
  props: {},
  computed: {
    ...mapGetters({
      getComponent: `ui/${GET_HOMEPAGE_VIDEO_COMPONENT}`
    }),
    // eslint-disable-next-line vue/return-in-computed-property
    backgroundVideo() {
      if (this.getComponent) {
        if (this.getComponent.media) {
          return {
            video_url: this.getComponent.media.video_url,
            image_url: this.getComponent.media.url
          };
        }
      }
    }
  }
};
</script>
