<template>
  <a
    :class="socialMediaType"
    href
    @click.prevent="openPopup(socialMediaType)"
    >{{ socialLinkLabel ? socialLinkLabel : socialMediaType | capitalize }}</a
  >
</template>

<script>
export default {
  name: "SocialShare",
  props: {
    socialMediaType: {
      type: String,
      default: "facebook"
    },
    socialLinkLabel: {
      type: String,
      default: ""
    },
    pageUrl: {
      type: String,
      default: window.location.href
    },
    title: {
      type: String,
      default:
        "Check out motorcycle tips, trips and an interactive hazard test at ridetolive.nsw.gov.au."
    },
    width: {
      type: Number,
      default: 626
    },
    height: {
      type: Number,
      default: 328
    }
  },
  computed: {
    left() {
      return screen.width / 2 - this.width / 2;
    },
    top() {
      return screen.height / 2 - this.height / 2;
    }
  },
  filters: {
    capitalize: value => {
      if (!value) return "";
      value = value.toString();
      return value.charAt(0).toUpperCase() + value.slice(1);
    }
  },
  methods: {
    openPopup(socialMediaType) {
      switch (socialMediaType) {
        case "facebook":
          window.open(
            "http://www.facebook.com/sharer.php?u=" +
              encodeURIComponent(this.pageUrl) +
              "&t=" +
              encodeURIComponent(this.title),
            "sharer",
            "toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=no, resizable=no, copyhistory=no, width=" +
              this.width +
              ", height=" +
              this.height +
              ", top=" +
              this.top +
              ", left=" +
              this.left
          );
          break;

        case "twitter":
          window.open(
            "http://www.twitter.com/share?url=/&text=Love to ride? Check out motorcycle tips, trips and an interactive hazard test at ridetolive.nsw.gov.au",
            "sharer",
            "toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=no, resizable=no, copyhistory=no, width=" +
              this.width +
              ", height=" +
              this.height +
              ", top=" +
              this.top +
              ", left=" +
              this.left
          );
          break;

        default:
          // eslint-disable-next-line no-console
          console.error("At least one social media type specified");
      }
    }
  }
};
</script>
