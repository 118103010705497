export const TOGGLE_MOBILE_MENU = "toggleMobileMenu";
export const CLOSE_MOBILE_MENU = "closeMobileMenu";
export const LOADING_START = "preloadingStart";
export const LOADING_END = "preloadingEnd";
export const SET_TIP_LOADED = "tipLoaded";
export const SET_TIPS_POLL = "tipsPoll";
export const SET_TIPS_LANDING_LOADED = "tipsLandingLoaded";
export const SET_TRIPS = "setTrips";
export const SET_MAP = "setMap";
export const SET_BOUNDS = "setBounds";
export const SET_MARKERS = "setMarkers";
export const SET_MARKER_ICON = "setIcon";
export const RESET_MAP = "resetMap";
export const SET_TRIP = "setTrip";
export const SET_CITY = "setCity";
export const TRAFFIC_LAYER_ON = "trafficLayerOn";
export const TRAFFIC_LAYER_OFF = "trafficLayerOff";
export const SET_TIPS_LOADING_STATE = "tipsLoadedState";
export const SET_LANDING_TIPS_LOADING_STATE = "tipsLandingLoadedState";
export const SET_HOMEPAGE = "setHomepage";
export const SET_HOMEPAGE_TRIPS = "setHomepageTrips";
export const SET_METATAG = "setMetatag";
export const MAP_EXPANDED_ON = "mapExpandedOn";
export const MAP_EXPANDED_OFF = "mapExpandedOff";
export const SET_SELECTED_FILTER = "setSelectedFilter";
export const TRIP_FORM_OPEN = "tripFormOpen";
export const TRIP_FORM_CLOSE = "tripFormClose";
export const TOGGLE_MARKERS = "toggleMarkers";
export const SET_DIRECTIONS_RENDERER = "setDirectionsRenderer";
export const SET_TRIP_DIRECTIONS = "setTripDirections";
export const SET_TRIP_SUGGESTED_PATH = "setTripSuggestedPath";
export const SET_SUGGEST_TRIP_FORM = "setSuggestTripForm";
export const SET_SUGGEST_TRIPWAY_A = "setSuggestTripwayA";
export const SET_SUGGEST_TRIPWAY_B = "setSuggestTripwayB";
export const SET_SUGGEST_TRIP_MARKERS = "setSuggestTripMarkers";
export const RESET_SUGGEST_TRIP_MARKERS = "resetSuggestTripMarkers";
export const RESET_SUGGEST_TRIP_DATA = "resetSuggestTripData";
export const RESET_DIRECTIONS_RENDERER = "resetDirectionsRenderer";
export const RESTORE_ZOOM = "resetZoom";
export const TRIP_FORM_SUBMIT_REQUEST = "tripFormSubmitRequest";
export const TRIP_FORM_SUBMIT_END = "tripFormSubmitEnd";
export const SET_ACCESS_TOKEN = "setAccessToken";
export const OPEN_MODAL = "openModal";
export const CLOSE_MODAL = "closeModal";
export const SET_UPVOTE = "setUpvote";
export const SET_DOWNVOTE = "setDownvote";
