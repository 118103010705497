/* eslint-disable no-undef */
import get from "lodash.get";
import { tripLandingServices, tripServices } from "@/api/services";
import { metatagService } from "@/api/meta-services";
import {
  FETCH_TRIPS,
  FETCH_TRIP,
  SUGGEST_TRIP_CREATE,
  UP_VOTE_REQUEST,
  DOWN_VOTE_REQUEST
} from "../actions-type";
import {
  SET_TRIPS,
  SET_MAP,
  SET_BOUNDS,
  SET_MARKERS,
  SET_MARKER_ICON,
  SET_TRIP,
  SET_CITY,
  RESET_MAP,
  TRAFFIC_LAYER_ON,
  TRAFFIC_LAYER_OFF,
  MAP_EXPANDED_ON,
  MAP_EXPANDED_OFF,
  TRIP_FORM_OPEN,
  TRIP_FORM_CLOSE,
  TOGGLE_MARKERS,
  SET_DIRECTIONS_RENDERER,
  SET_TRIP_DIRECTIONS,
  SET_TRIP_SUGGESTED_PATH,
  SET_SUGGEST_TRIP_FORM,
  SET_SUGGEST_TRIPWAY_A,
  SET_SUGGEST_TRIPWAY_B,
  SET_SUGGEST_TRIP_MARKERS,
  RESET_SUGGEST_TRIP_MARKERS,
  RESET_SUGGEST_TRIP_DATA,
  RESET_DIRECTIONS_RENDERER,
  RESTORE_ZOOM,
  TRIP_FORM_SUBMIT_REQUEST,
  TRIP_FORM_SUBMIT_END,
  SET_ACCESS_TOKEN,
  OPEN_MODAL,
  CLOSE_MODAL,
  SET_UPVOTE,
  SET_DOWNVOTE
} from "../mutation-type";
import {
  GET_TRIPS_HERO,
  GET_TRIPS,
  GET_TRIPS_POI,
  GET_TRIP,
  GET_TRIP_POI,
  GET_METADATA,
  GET_TRIP_IMAGES,
  GET_TRIP_IMAGES_LARGE,
  GET_WEATHER_DATA
} from "../getters-type";

const state = {
  metadata: [],
  trips: {
    title: "",
    field_hero_image: {
      field_media_image: {
        links: {
          trip_tile: {
            href: ""
          }
        }
      }
    },
    field_trips: [
      {
        title: "",
        field_trip_type: {
          name: ""
        },
        field_hero_image: {
          field_media_image: {
            meta: {
              imageDerivatives: {
                links: {
                  trip_tile: {
                    href: ""
                  }
                }
              }
            }
          }
        },
        json_map_data: []
      }
    ]
  },
  trip: {
    field_city_weather: [],
    field_trip_type: {},
    field_images: [
      {
        field_media_image: {
          meta: {
            imageDerivatives: {
              links: {
                trip_tile: {
                  href: ""
                }
              }
            }
          }
        }
      }
    ]
  },
  mapConfig: {
    draggable: true,
    zoom: 7,
    disableDoubleClickZoom: false
  },
  defaultMapCenter: new google.maps.LatLng(-33.86, 151.2094),
  map: null,
  bounds: null,
  markers: [],
  icon: null,
  city: [],
  trafficVisibile: false,
  expanded: false,
  isTripFormOpened: false,
  isTripFormSubmitted: false,
  suggestTripMarkers: [],
  suggestTripName: "",
  suggestDescription: "",
  suggestStart: "",
  suggestEnd: "",
  directionsRenderer: null,
  suggestTripDirections: null,
  suggestTripFormOrigin: null,
  suggestTripFormDestination: null,
  suggestTripWayA: null,
  suggestTripWayB: null,
  suggestTripDataStart: null,
  suggestTripDataEnd: null,
  modalOpen: false,
  modalTitle: "LIKE THIS RIDE?",
  modalBody: "Thank you for your vote.",
  upVoteCount: 0,
  downVoteCount: 0
};

const getters = {
  [GET_METADATA]: state => {
    return state.metadata;
  },
  [GET_TRIPS_HERO]: state => {
    return {
      title: state.trips.title,
      heroImageUrl: get(
        state,
        "trips.field_hero_image.field_media_image.links.trip_tile.href",
        ""
      )
    };
  },
  [GET_TRIPS]: state => {
    return state.trips.field_trips.map(item => {
      return {
        id: item.id,
        title: item.title,
        field_trip_type: item.field_trip_type.name,
        field_origin: item.field_origin,
        field_destination: item.field_destination,
        field_length: item.field_length,
        field_slug: item.field_slug,
        trip_tile: get(
          item,
          "field_hero_image.field_media_image.meta.imageDerivatives.links.trip_tile.href",
          ""
        )
      };
    });
  },
  [GET_TRIPS_POI]: state => {
    return state.trips.field_trips.map(item => {
      const city = item.json_map_data.filter(poi => {
        return poi.type === "city" || poi.type === "trip";
      });
      return {
        id: item.id,
        title: item.title,
        field_origin: item.field_origin,
        field_trip_type: item.field_trip_type.name,
        field_description: item.field_description,
        field_destination: item.field_destination,
        field_length: item.field_length,
        field_slug: item.field_slug,
        destination_geo: city.pop()
      };
    });
  },
  [GET_TRIP]: state => {
    const {
      id,
      title,
      field_origin,
      field_destination,
      field_description,
      field_duration,
      field_featured_trip,
      field_length,
      field_trip_type,
      json_map_data,
      field_slug,
      field_hazards,
      field_top_highlights,
      vote_count
    } = state.trip;
    const hours = Math.floor(field_duration / 60);
    const minutes = field_duration % 60;
    return {
      id,
      title,
      field_origin,
      field_destination,
      field_description,
      field_duration,
      field_slug,
      hours,
      minutes,
      field_featured_trip,
      field_length,
      field_trip_type: field_trip_type.name,
      json_map_data,
      field_hazards,
      field_top_highlights,
      vote_count
    };
  },
  [GET_TRIP_POI]: state => {
    const { field_trip_type } = state.trip;
    return [
      {
        field_trip_type: field_trip_type.name,
        json_map_data: state.trip.json_map_data
      }
    ];
  },
  [GET_TRIP_IMAGES]: state => {
    return state.trip.field_images.map(item => {
      return {
        id: item.id,
        caption: item.name,
        field_description: item.field_description,
        name: get(
          item,
          "field_media_image.meta.imageDerivatives.links.trip_tile.href",
          ""
        )
      };
    });
  },
  [GET_TRIP_IMAGES_LARGE]: state => {
    return state.trip.field_images.map(item => {
      return {
        id: item.id,
        caption: item.name,
        field_description: item.field_description,
        name: get(
          item,
          "field_media_image.meta.imageDerivatives.links.hero.href",
          ""
        )
      };
    });
  },
  [GET_WEATHER_DATA]: state => {
    return state.trip.field_city_weather.map(item => {
      return get(item, "weather_data[0]", null);
    });
  }
};

const actions = {
  async [FETCH_TRIPS]({ commit }) {
    try {
      commit(RESET_MAP);
      const data = await tripLandingServices.get();
      commit(SET_TRIPS, data);
    } catch (err) {
      // eslint-disable-next-line no-console
      console.log("Errors:", err.message);
    }
  },
  async [FETCH_TRIP]({ commit }, slug) {
    try {
      commit(RESET_MAP);
      const data = await tripServices.get(slug);
      commit(SET_TRIP, data);

      if (
        data[0].vote_count &&
        typeof data[0].vote_count.up_vote !== "undefined"
      ) {
        commit(SET_UPVOTE, data[0].vote_count.up_vote.vote_count);
      } else {
        commit(SET_UPVOTE, 0);
      }

      if (
        data[0].vote_count &&
        typeof data[0].vote_count.down_vote !== "undefined"
      ) {
        commit(SET_DOWNVOTE, data[0].vote_count.down_vote.vote_count);
      } else {
        commit(SET_DOWNVOTE, 0);
      }
    } catch (err) {
      // eslint-disable-next-line no-console
      console.log("Errors:", err.message);
    }
  },
  async [SUGGEST_TRIP_CREATE]({ commit }, payload) {
    try {
      await tripServices.createTripSuggestion("/webform_rest/submit", payload);
      commit(TRIP_FORM_SUBMIT_REQUEST);
    } catch (err) {
      // eslint-disable-next-line no-console
      console.log("Errors:", err.message);
    }
  },
  async [UP_VOTE_REQUEST]({ commit }, payload) {
    try {
      await tripServices.postVote(
        "/api/vote/up_vote",
        /* eslint-disable prettier/prettier */
        {
        "data": {
          "type": "vote--up_vote",
          "attributes": {
            "entity_type": "trip",
            "value": "1",
            "value_type": "points"
          },
          "relationships": {
            "entity_id": {
              "data": {
                "type": "node--trip",
                "id": payload.tripId
              }
            }
          }
        }
      }, {
        headers: {
        "Accept": "application/vnd.api+json",
        "Content-Type": "application/vnd.api+json"
        }});
      commit(OPEN_MODAL);
      commit(SET_UPVOTE, payload.upVoteCount);
    } catch (err) {
      alert('An unexpected error occoured trying to retreive trip vote data.');
    }
  },
  async [DOWN_VOTE_REQUEST]({ commit }, payload) {
    try {
      await tripServices.postVote("/api/vote/down_vote", {
        "data": {
          "type": "vote--down_vote",
          "attributes": {
            "entity_type": "trip",
            "value": "1",
            "value_type": "points"
          },
          "relationships": {
            "entity_id": {
              "data": {
                "type": "node--trip",
                "id": payload.tripId
              }
            }
          }
        }
      }, {
        headers: {
        "Accept": "application/vnd.api+json",
        "Content-Type": "application/vnd.api+json"
        }});
      commit(OPEN_MODAL);
      commit(SET_DOWNVOTE, payload.downVoteCount);
    } catch (err) {
      alert('An unexpected error occoured trying to retreive trip vote data.');
    }
  }
};

const mutations = {
  [SET_ACCESS_TOKEN](state, token) {
    state.accessToken = token;
  },
  [SET_TRIPS](state, payload) {
    state.metadata = metatagService.update(payload.metatag);
    state.trips = payload;
  },
  [SET_TRIP](state, payload) {
    state.metadata = metatagService.update(payload[0].metatag);
    state.trip = payload[0];
  },
  [SET_MAP](state, payload) {
    state.map = payload;
  },
  [SET_BOUNDS](state, payload) {
    state.bounds = payload;
  },
  [SET_MARKERS](state, payload) {
    state.markers.push(payload);
  },
  [SET_MARKER_ICON](state, payload) {
    state.icon = payload;
  },
  [SET_CITY](state, payload) {
    state.city = payload;
  },
  [RESET_MAP](state) {
    state.map = null;
    state.bounds = null;
    state.markers = [];
    state.icon = null;
    state.city = [];
    state.trafficVisibile = false;
  },
  [TRAFFIC_LAYER_ON](state) {
    state.map.trafficLayer.setMap(state.map);
    state.trafficVisibile = true;
  },
  [TRAFFIC_LAYER_OFF](state) {
    state.map.trafficLayer.setMap(null);
    state.trafficVisibile = false;
  },
  [MAP_EXPANDED_ON](state) {
    state.expanded = true;
  },
  [MAP_EXPANDED_OFF](state) {
    state.expanded = false;
  },
  [TRIP_FORM_OPEN](state) {
    state.isTripFormOpened = true;
  },
  [TRIP_FORM_SUBMIT_REQUEST](state) {
    state.isTripFormSubmitted = true;
  },
  [TRIP_FORM_SUBMIT_END](state) {
    state.isTripFormSubmitted = false;
  },
  [TRIP_FORM_CLOSE](state) {
    state.isTripFormOpened = false;
  },
  [TOGGLE_MARKERS](state, visible) {
    state.markers.forEach(marker => {
      if (visible) {
        marker.setMap(state.map);
      } else {
        marker.setMap(null);
      }
    });
  },
  [SET_DIRECTIONS_RENDERER](state, payload) {
    state.directionsRenderer = payload;
  },
  [SET_TRIP_DIRECTIONS](state, payload) {
    state.suggestTripDirections = payload;
  },
  [SET_TRIP_SUGGESTED_PATH](state, payload) {
    state.suggestTripDataStart = payload.start;
    state.suggestTripDataEnd = payload.end;
  },
  [SET_SUGGEST_TRIP_FORM](state, origin, destination) {
    state.suggestTripFormOrigin = origin;
    state.suggestTripFormDestination = destination;
  },
  [SET_SUGGEST_TRIPWAY_A](state, payload) {
    state.suggestTripWayA = payload;
  },
  [SET_SUGGEST_TRIPWAY_B](state, payload) {
    state.suggestTripWayB = payload;
  },
  [SET_SUGGEST_TRIP_MARKERS](state, payload) {
    state.suggestTripMarkers.push(payload);
  },
  [RESET_SUGGEST_TRIP_MARKERS](state) {
    state.suggestTripMarkers = [];
  },
  [RESET_SUGGEST_TRIP_DATA](state) {
    state.suggestTripWayA = null;
    state.suggestTripWayB = null;
    state.suggestTripDirections = null;
    state.suggestTripFormOrigin = null;
    state.suggestTripFormDestination = null;
    state.suggestTripDataStart = null;
    state.suggestTripDataEnd = null;
  },
  [RESET_DIRECTIONS_RENDERER](state) {
    state.directionsRenderer.setMap(null);
    state.directionsRenderer = [];
  },
  [RESTORE_ZOOM](state) {
    state.map.setCenter(state.defaultMapCenter);
    state.map.setZoom(7);
  },
  [OPEN_MODAL](state) {
    state.modalOpen = true;
  },
  [CLOSE_MODAL](state) {
    state.modalOpen = false;
  },
  [SET_UPVOTE](state, payload) {
    state.upVoteCount = payload;
  },
  [SET_DOWNVOTE](state, payload) {
    state.downVoteCount = payload;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
