<template>
  <div class="layout-landing full-height">
    <section id="video" class="col-md-7 slideFadeInLeft">
      <HeroCTA />
      <VideoCTA />
    </section>
    <aside class="col-md-5">
      <TripCTA />
      <FeatureTripsCTA />
      <TipsCTA />
    </aside>
  </div>
</template>

<script>
import HeroCTA from "@/components/homepage/HeroCTA.vue";
import VideoCTA from "@/components/homepage/VideoCTA.vue";
import TripCTA from "@/components/homepage/TripCTA";
import TipsCTA from "@/components/homepage/TipsCTA";
import FeatureTripsCTA from "@/components/homepage/FeatureTripsCTA";

import { mapActions } from "vuex";
import { FETCH_HOMEPAGE, FETCH_HOMEPAGE_TRIPS } from "@/store/actions-type";

export default {
  name: "HomePage",
  components: {
    TipsCTA,
    TripCTA,
    HeroCTA,
    VideoCTA,
    FeatureTripsCTA
  },
  props: {},
  computed: {},
  mounted() {
    this.fetchHomepage();
    this.fetchHomepageTrips();
  },
  methods: {
    ...mapActions({
      fetchHomepage: `ui/${FETCH_HOMEPAGE}`,
      fetchHomepageTrips: `ui/${FETCH_HOMEPAGE_TRIPS}`
    })
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss"></style>
