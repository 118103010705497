// Metatag service.
export const metatagService = {
  /**
   * Get metatag from jsonapi response.
   */
  update(metatag) {
    let metatagList = [];
    metatag.forEach(value => {
      if (value.attributes) {
        metatagList.push(value.attributes);
      }
    });
    return metatagList;
  }
};
