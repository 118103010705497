import jsonapiParse from "jsonapi-parse";
import axios from "axios";
import qs from "qs";

const BASE_URL = "https://" + location.hostname;
const API_PREFIX = "/api/node/";

export const apiServices = axios.create({
  baseURL: BASE_URL + API_PREFIX,
  headers: {
    Accept: "application/vnd.api+json"
  },
  useCredentails: false
});

apiServices.interceptors.response.use(response => {
  return jsonapiParse.parse(response.data).data;
});

export default {
  get: (uri, params = null, config) => {
    const query = params ? "?" + qs.stringify(params, { indices: false }) : "";
    const url = `${uri}${decodeURIComponent(query)}`;
    return apiServices.get(url, config);
  }
};
