<template>
  <div class="trip-planner box">
    <div class="panel">
      <div class="inner" v-if="getComponent">
        <h3>{{ getComponent.title }}</h3>
        <p v-if="getComponent.description">{{ getComponent.description }}</p>
        <a
          v-if="getComponent.link"
          :href="`${getComponent.link.uri}`"
          class="btn black"
          >{{ getComponent.link.title }}
        </a>
      </div>
    </div>
    <div class="map">
      <GoogleMap
        v-if="featuredTripGeoData.length"
        id="homepage-trip-cta"
        name="TripCTA"
        :tripList="true"
        :mapConfig="mapConfig"
        :mapData="featuredTripGeoData"
        :navControl="false"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState, mapMutations } from "vuex";
import { RESET_MAP } from "@/store/mutation-type";
import GoogleMap from "@/components/GoogleMap";
import {
  GET_HOMEPAGE_TRIP_COMPONENT,
  GET_FEATURED_TRIPS
} from "@/store/getters-type";

export default {
  name: "TripCTA",
  components: { GoogleMap },
  props: {},
  created() {
    this.setResetMap();
  },
  computed: {
    ...mapState({
      mapConfig: state => state.ui.mapConfig
    }),
    ...mapGetters("ui", {
      getComponent: `${GET_HOMEPAGE_TRIP_COMPONENT}`,
      featuredTripGeoData: `${GET_FEATURED_TRIPS}`
    })
  },
  methods: {
    ...mapMutations("trip", {
      setResetMap: `${RESET_MAP}`
    })
  }
};
</script>
<style lang="scss">
.map #google-map {
  width: 100%;
  height: 100%;
  -webkit-animation: displayNoneFadeIn 3s cubic-bezier(0.39, 1, 0.52, 0.99);
}
</style>
