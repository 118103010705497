<template>
  <footer class="col-md-12">
    <nav class="social">
      <ul>
        <li>
          <SocialShare socialMediaType="twitter" />
        </li>
        <li>
          <SocialShare socialMediaType="facebook" />
        </li>
      </ul>
    </nav>
    <nav class="legal">
      <ul>
        <li>
          <a
            href="https://www.transport.nsw.gov.au/online-privacy"
            target="_blank"
            >Privacy</a
          >
        </li>
        <li>
          <a
            href="http://roadsafety.transport.nsw.gov.au/stayingsafe/motorcyclists/index.html"
            target="_blank"
            >Road Safety</a
          >
        </li>
        <li>
          <a href="/pages/site-terms">Site Terms</a>
        </li>
      </ul>
    </nav>
  </footer>
</template>

<script>
import SocialShare from "@/components/SocialShare.vue";

export default {
  name: "Footer",
  components: {
    SocialShare
  }
};
</script>
